<template>
  <temeno-button
    v-bind="$attrs"
    v-on="$listeners"
    :action="action"
    :loading="loading"
    @click="updatePassword"
  >
    <v-icon :action="action" left>$lock</v-icon>
    Passwort ändern
  </temeno-button>
</template>

<script>
import api from "@/api";

export default {
  name: "UpdatePasswordButton",
  components: {
    TemenoButton: () => import("@/components/TemenoButton"),
  },
  props: {
    action: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    updatePassword() {
      this.loading = true;
      api
        .updatePassword()
        .then(() => {
          this.$store.dispatch("snackbar/addMail", "Ein Link zur Änderung Ihres Passworts wurde an Ihre E-Mail-Adresse versendet.");
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("snackbar/addError", "Ein unerwarteter Fehler ist aufgetreten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
